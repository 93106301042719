import React from 'react';
import {HeadingH2, PageContent} from "../styles/GlobalStyles";
import {Underline} from "../styles/Underline";
import {Button} from "../styles/Button";
import {StaticImage} from "gatsby-plugin-image";
import styled from "styled-components";


const AboutConstant = () => {

    return (
        <PageContent>

            <AboutItems>
                <HeadingH2>Apie Mus</HeadingH2>
                <Underline fluid primary small/>
                <AboutP>Investavome daug laiko ir pastangų, kad pagaliau turėtume tokį Bohabo Cornhole žaidimą, kuriuo
                    galime didžiuotis. Visus oficialius ACL (American Cornhole League) reikalavimus atitinkantys Cornhole maišeliai ir lentos,
                    pagamintos iš aukščiausios kokybės medžiagų, suteiks Jums galimybę mėgautis žaidimu kiekvieną kartą
                    net ir pačiomis blogiausiomis oro sąlygomis.</AboutP>
                <Button to="/kontaktai" big="true">Susisiekite su Mumis</Button>
            </AboutItems>

            <AboutImg>
                    <StaticImage src="../assets/images/single-bag-blue.png" placeholder="none" quality="100" alt="Bohabo hero image"/>
            </AboutImg>

        </PageContent>
    );
};

export default AboutConstant;

const AboutItems = styled.div`
    grid-column: 1/-1;  
    text-align: center;
    align-self: center;
    justify-self: center;
  @media screen and (min-width: ${props => props.theme.breakpoints.laptop}) {
    grid-column: 1/8;
    text-align: left;
  }
`;

const AboutP = styled.p`
  color: ${props => props.theme.black};
  font-size: clamp(14px, 3vw, 18px);
  margin: 0 auto 2rem;
  
  @media screen and (min-width: ${props => props.theme.breakpoints.laptop}) {
    margin: 4rem 0 5rem;
  }
`;

const AboutImg = styled.div`
  grid-column: 1/-1;
  justify-self: center;
  width: 100%;
  max-width: 500px;
  
  @media screen and (min-width: ${props => props.theme.breakpoints.laptop}) {
    grid-row: 1/1;
    grid-column: 8/13;
    transform: translateX(40px);
    max-width: 100%;
  }
`;