import React from "react"
import Layout from "../components/Layout"
import {PageWrapper, PageContainer} from "../styles/GlobalStyles";
import AboutContent from "../constants/about";
import Seo from "../components/SEO";

const AboutPage = () => {

  return (
      <Layout>
          <Seo title="Apie Mus - Bohabo Cornhole Žaidimas"
               description="Esame Cornhole žaidimo entuziastai ir platintojai Lietuvoje. Didžiuojamės galėdami Jums pasiūlyti aukščiausius reikalavimus atitinkančią Cornhole žaidimo įrangą."
          />
          <PageWrapper>
              <PageContainer>
                 <AboutContent/>
              </PageContainer>
          </PageWrapper>
      </Layout>
  )
};

export default AboutPage;

